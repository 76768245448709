//@ts-check
import queryString from 'query-string';
import { FILEEE_DOMAINS, getBaseDomainUrl, isProductionEnv } from 'common/utils';

/* for both query params and hash params */
export function getSearchParams() {
  if (BUILD_VAR_ROUTER_PUSH_STATE === true) {
    return queryString.parse(window.location.search) || {};
  }

  if (BUILD_VAR_ROUTER_PUSH_STATE === false) {
    const hashPart = window.location.hash || '';
    const queryStart = hashPart.indexOf('?');
    if (queryStart === -1) {
      return {};
    }
    const queryPart = hashPart.slice(queryStart + 1);
    return queryString.parse(queryPart) || {};
  }
  return {};
}

export function getSearchOnlyParams() {
  return queryString.parse(window.location.search) || {};
}

export function getBaseReloadLocation() {
  if (BUILD_VAR_ROUTER_PUSH_STATE === true) {
    return '';
  }
  if (BUILD_VAR_ROUTER_PUSH_STATE === false) {
    return window.location.origin + window.location.pathname + '#';
  }
}

export function getLocationPathName() {
  if (BUILD_VAR_ROUTER_PUSH_STATE === true) {
    return window.location.pathname;
  }
  let hashPath = window.location.hash;

  if (!hashPath) return '';

  if (!hashPath.includes('#')) return '';

  /**
   * Example hash path = #/login?hello=world
   */
  let pathIgnoringHash = hashPath.substring(hashPath.indexOf('#') + 1);

  let pathIgnoringSearch = pathIgnoringHash.substring(
    0,
    hashPath.indexOf('?') > 0 ? hashPath.indexOf('?') - 1 : pathIgnoringHash.length
  );

  return pathIgnoringSearch;
}

export function getHostName() {
  return window.location.hostname;
}

/**
 * Exported only for testing
 * @param {string} shareId
 * @returns {string | null}
 */
export function getLinkFromShareId(shareId) {
  if (shareId) {
    const currentDomain = getBaseDomainUrl();
    if (FILEEE_DOMAINS.has(currentDomain)) {
      return `${currentDomain}/shared/${shareId}`;
    } else {
      return `https://my.fileee.com/shared/${shareId}`;
    }
  } else {
    return null;
  }
}

export function getSupportActionLink() {
  if (isProductionEnv()) {
    // https://file.ee/serviceanfrage
    return '/actions/50ac222b0cf2f924c9ccb919-5ef1fbebe8dd85567073794d';
  } else {
    return '/actions/50ac222b0cf2f924c9ccb919-5ef1fbebe8dd85567073794d';
  }
}

export function getBaseRelativeUrl() {
  if (!BUILD_VAR_ROUTER_PUSH_STATE) {
    return '#/';
  } else {
    return '/';
  }
}

/**
 *
 * @param {string} link Relative Link
 */
export function fullPageRedirectTo(link) {
  window.location.href = `${getBaseReloadLocation()}${link}`;
}
