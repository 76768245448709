import '../styles/master.scss';
import '../styles/tailwind.css';

import { getLocationPathName } from './common/location-utils';

import ReactModal from 'react-modal';
import { logout } from 'views/services/api-caller';
import { getGlobalSettings } from 'common/global-settings';
import { setupLogging } from 'startup/setup-logging';
import { setupRollbar } from 'startup/setup-rollbar';
import { setLoggedOut } from 'common/login-logout-utils';

import { getLogger } from 'logger';
import { setupI18n } from 'startup/setup-i18n';
import { setupCookies } from 'startup/setup-cookies';
import { AppContainerType, renderRootApp } from 'app-renderer';
import { doJsStartActions } from 'startup/js-start-actions';
import { getTimeLoadingSinceStartup, isLoggedIn } from 'common/utils';

setupRollbar();
setupLogging();

let logger = getLogger('entry');
logger.info('entry loaded in ' + getTimeLoadingSinceStartup() + 'ms');

(function (gl) {
  try {
    if (gl.analytics) return;
    let analytics = [];
    let functions = ['identity', 'track', 'alias', 'register', 'unregister', 'init', 'reset'];

    functions.forEach((name) => {
      analytics[name] = function () {
        analytics.push([name, ...arguments]);
      };
    });
    gl.analytics = analytics;
  } catch (error) {
    logger.error(`Failed to initiate analytics script ${error}`);
  }
})(window);

let globalSettings = getGlobalSettings();

console.log('[IN] runtimeEnv!!: ' + globalSettings.runtimeEnv);
if (globalSettings.userId) console.log('[IN] userId: ' + globalSettings.userId);

ReactModal.setAppElement('#fileee-app');

function startUp() {
  doJsStartActions();
  const blockingPromises = [setupI18n(), setupCookies()];
  Promise.all(blockingPromises).then(() => {
    return resolveInitialPageByState().then((value) => {
      renderRootApp(value);
    });
  });
}

startUp();

async function resolveInitialPageByState(): Promise<AppContainerType> {
  let pathname = getLocationPathName();

  //shared view?
  if (pathname.startsWith('/shared/')) {
    return isLoggedIn() ? 'container' : 'container-login';
  } else if (pathname.startsWith('/external/communication')) {
    // Logout real user if the app is starting in external communication view
    if (isLoggedIn()) {
      logger.info(`A user is already logged in. Logout current user and reload`);
      try {
        await logout();
        setLoggedOut(() => {
          window.location.reload();
        });
      } catch (error) {
        console.log('Failed to logout', error);
      }
    }

    return 'container-external';
  } else {
    if (isLoggedIn() && shouldGoToModularizedApp(pathname)) {
      return 'module-container';
    }
    //logged in user can go wherever they want to for now.
    if (isLoggedIn() && !shouldIgnoreLogin(pathname)) {
      return 'container';
    } else {
      if (pathname.startsWith('/actions/')) {
        return 'container-external';
      }
      return 'container-login';
    }
  }
}

// paths included in this always will always go to login container, even if the user is logged in
// TODO may be the log the user out on client side before navigating to the ignored routes
const ignoreLoginPaths = ['/new-password/', '/openid/login'];

function shouldIgnoreLogin(pathname) {
  for (let i = 0; i < ignoreLoginPaths.length; i++) {
    let path = ignoreLoginPaths[i];
    if (pathname.startsWith(path)) {
      return true;
    }
  }
  return false;
}

const modularizedPaths = ['/module/communication', '/module/conversations-editor'];

function shouldGoToModularizedApp(pathname) {
  for (let i = 0; i < modularizedPaths.length; i++) {
    let path = modularizedPaths[i];
    if (pathname.startsWith(path)) {
      return true;
    }
  }
  return false;
}

// const DISABLED_XSRF_HOSTS = new Set([
//   'beta.workeee.de',
//   'dev-my.workeee.de',
//   'www.vgh.de',
//   'vgh.d.oevcloud.de',
//   'vgh.i.oevcloud.de',
//   'vgh.r.oevcloud.de',
//   'oesa.d.oevcloud.de',
//   'oesa.i.oevcloud.de',
//   'oesa.r.oevcloud.de',
//   'oevb.d.oevcloud.de',
//   'oevb.i.oevcloud.de',
//   'oevb.r.oevcloud.de',
//   'oevo.d.oevcloud.de',
//   'oevo.i.oevcloud.de',
//   'oevo.r.oevcloud.de',
//   'd.oevcloud.de',
//   'i.oevcloud.de',
//   'r.oevcloud.de',
//   'oev.d.oevcloud.de',
//   'oev.i.oevcloud.de',
//   'oev.r.oevcloud.de',
//   'www.oevdigital.de',
//   'www.oesa.de',
//   'www.oevb.de',
//   'www.oeffentlicheoldenburg.de'
// ]);

// const XSRF_COOKIE_NAME = 'XSRF-TOKEN';
// const XSRF_HEADER_NAME = 'X-XSRF-TOKEN';

window.__fileeeCaches = {};

document.fileeeBasicVar.version = __GIT_REVISION__;
